import { CURRENCIES_SYMBOL } from "src/app/core/common/currency-constants";
import { ProhibitionType } from "src/app/core/models/domain/prohibition/ProhibitionType";

export const campaignConfig = {
  campaignProhibitions: ["None", ProhibitionType.ALCOHOL, ProhibitionType.CANNABIS, ProhibitionType.NICOTINE],
  costTypeDisplayConfig: {
    content: [
      {
        code: "PRODUCTION",
        order: 1,
      },
    ],
  },
  showFrameImages: true,
  setCampaignAudienceToPrimaryAudience: false,
  isRouteVersionMandatory: false,
  isDateFormatDDMMYYYY: true,
  hfss_disabled: true,
  loadInchargeStartDates: false,
  displayHFSS: false,
  displayProhibition: true,
  displayPrimaryAudience: false,
  displayTargetAudience: true,
  displayRouteVersion: false,
  updateBillingUnitWithMOVal: false,
  displayValueMediaAndPacks: false,
  currencySymbol: CURRENCIES_SYMBOL.CA,
  disableCursor: true,
  displayFeePercentageCol: true,
  downloadCampaignBookings: true,
  exportCampaign: true,
  allowMediaUpload: true,
  currencyList: ["USD", "CAD", "GBP"],
  addExchangeMultiplierControl: true,
  addFeeCalculationTypeControl: true,
  allowCampaignLevelMarginMarkup: true,
  allowMarginMarkupToEdit: true,
  customAgencySACValue: false,
  addPrincipalBeneficiaryControl: true,
  displayClientOrderNumber: true,
  displayAgencyCode: true,
  displayCustomerReferenceNumber: false,
  showPlacementReference: true,
  showCustomerReference: true,
  hideExternalProductionCostAndPostingCharges: true,
  disableTvRegionForNationalReach: true,
  isTvRegionMandatoryInFFCGeography: true,
  displayCancellationPolicyPop: true,
  closeCampaignSaveConfirmation: true,
  disableSave: false,
  shouldDisableLineAmendment: false,
  hideCustomerReference: true,
  setMarginLabel: false,
  showPrepareOption: false,
  showAvailsAction: false,
  displayAmendment: false,
  displayExternalProdCost: false,
  displayBillingUnit: true,
  loadGeoLocationForStateAndCountry: true,
  updateDMADropDown: true,
  setPrimaryAudience: true,
  setPrimaryAudienceAndKPI: false,
  displayMediaOwnerFrameRef: true,
  ukSpecificCampaignBudgetComp: false,
  countryCodeForFinanceIdMapping: "CAN",
  showCityFieldInFFC: true,
  enablePBUExport: true,
  enableSupplierContract: false,
  displayAdaAudience: true,
  adaAudienceCountryCode: "ca",
};
