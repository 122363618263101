import { Component, Inject, TemplateRef, ViewChild } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CustomPoiSetService } from "./custom-poi-set.service";
import { Observable } from "rxjs";
import { CustomPOISet } from "../../../../../../core/models/domain/poi/custompoi/customPOISet";
import { FileTypes, FileSaver } from "src/app/core/svc/file-saver.service";
import { MessageBoxService } from "src/app/core/svc/message-box.service";

class CustomPoiDialogControl {
  private isSubmitButtonEnabledValue: boolean;
  isNameInputErrorVisible: boolean;
  isValidationResultVisible: boolean;
  isValidationFileDownloadEnable: boolean;
  isAnyOperationInProgress: boolean;

  constructor() {
    this.reset();
    this.isNameInputErrorVisible = false;
  }

  get isSubmitButtonEnabled() {
    return this.isSubmitButtonEnabledValue && !this.isAnyOperationInProgress;
  }

  get isVerifyButtonEnabled() {
    return !this.isAnyOperationInProgress;
  }

  reset() {
    this.isSubmitButtonEnabledValue = false;
    this.isValidationResultVisible = false;
    this.isValidationFileDownloadEnable = false;
    this.isAnyOperationInProgress = false;
  }

  enableSubmitButton() {
    this.isSubmitButtonEnabledValue = true;
  }

  showNameInputError() {
    this.isNameInputErrorVisible = true;
  }

  hideNameInputError() {
    this.isNameInputErrorVisible = false;
  }

  showValidationResult(withErrorFileDownload: boolean) {
    this.isValidationResultVisible = true;
    this.isValidationFileDownloadEnable = withErrorFileDownload;
  }

  startOperation() {
    this.isAnyOperationInProgress = true;
  }

  stopOperation() {
    this.isAnyOperationInProgress = false;
  }
}

@Component({
  selector: "app-custom-poi-dialog",
  templateUrl: "./custom-poi-dialog.component.html",
  styleUrls: ["./custom-poi-dialog.component.scss"],
})
export class CustomPoiDialogComponent {
  file: File;
  control = new CustomPoiDialogControl();
  setNameErrorMessage = "";
  customPOISetName: string;
  validRows: number;
  invalidRows: number;
  private customPOISetList: CustomPOISet[];
  private readonly _fileUploadBodyKey = "file";
  @ViewChild("custom_poi_error", { static: true })
  customPoiError: TemplateRef<Component>;
  customPoiErrorDialogRef: MatDialogRef<Component>;

  constructor(
    public dialogRef: MatDialogRef<Component>,
    private customPOISetService: CustomPoiSetService,
    @Inject(MAT_DIALOG_DATA) data: any,
    private fileSaver: FileSaver,
    private messageBoxService: MessageBoxService
  ) {
    this.customPOISetList = data.poiSetList ? data.poiSetList : [];
  }

  showErrorMessageDialog(message?: string): void {
    this.messageBoxService.showErrorMessage(message);
  }

  onFileSelection(fileList: any) {
    this.file = fileList[0];
    this.control.reset();
  }

  onProceed() {
    if (!this.validateSetName()) {
      return;
    }
    this.control.startOperation();
    this.uploadFile(this.file).subscribe(
      (fileUploadData) => {
        this.control.stopOperation();
        const dialogData = { poiSet: fileUploadData, fileName: this.file.name };
        this.dialogRef.close(dialogData);
      },
      (resp) => {
        this.control.stopOperation();
        const errorMessage = resp.error?.message || "An error occurred while uploading the file";
        this.showErrorMessageDialog(errorMessage);
      }
    );
  }

  onVerify() {
    const formData = this.getFileFormData();
    this.control.startOperation();
    this.customPOISetService.validatePOISet(formData).subscribe(
      (result) => {
        this.control.stopOperation();
        this.updateValidationResult(result.noOfValidRows, result.noOfInValidRows);
      },
      (e) => {
        console.error(e);
        this.control.stopOperation();
        const errorMessage = e.error?.message || "An error occurred while validating the file";
        this.showErrorMessageDialog(errorMessage);
      }
    );
  }

  onValidationResultDownload() {
    const formData = this.getFileFormData();
    this.customPOISetService.downloadValidationResult(formData).subscribe(
      (result) => this.saveCSVToDisk(result, "validation-result.csv"),
      (resp) => {
        console.error(resp);
        const errorMessage = resp.error?.message || "An error occurred while downloading the validation result";
        this.showErrorMessageDialog(errorMessage);
      }
    );
  }

  private getFileFormData() {
    const formData = new FormData();
    formData.append(this._fileUploadBodyKey, this.file);
    return formData;
  }

  private updateValidationResult(validRows: number, invalidRows: number): void {
    this.validRows = validRows;
    this.invalidRows = invalidRows;
    const hasInvalidRows = invalidRows > 0;
    this.control.showValidationResult(hasInvalidRows);
    if (!hasInvalidRows) {
      this.control.enableSubmitButton();
    }
  }

  private saveCSVToDisk(fileResponse: any, fileName: string): void {
    this.fileSaver.saveFileToDisk(fileResponse, fileName, FileTypes.CSV);
  }

  private uploadFile(file: File): Observable<any> {
    const formData = new FormData();
    formData.append(this._fileUploadBodyKey, file);
    formData.append("name", this.customPOISetName);
    return this.customPOISetService.uploadFile(formData);
  }

  private validateSetName() {
    if (!this.customPOISetName) {
      this.showErrorMessage("Please give a name");
      return false;
    }

    const nameAlreadyExists = !!this.customPOISetList.find((poiSet) => poiSet.name === this.customPOISetName.trim());
    if (nameAlreadyExists) {
      this.showErrorMessage("This name already exists");
      return false;
    }

    return true;
  }

  onTyping(event: any) {
    this.control.hideNameInputError();
  }

  private showErrorMessage(message: string) {
    this.control.showNameInputError();
    this.setNameErrorMessage = message;
  }

  downloadPoiSetTemplate() {
    this.customPOISetService.getTemplate().subscribe(
      (result) => {
        this.fileSaver.saveFileToDisk(result, "template.csv", FileTypes.CSV);
      },
      (resp) => {
        console.error(resp);
        const errorMessage = resp.error?.message || "An error occurred while downloading the template";
        this.showErrorMessageDialog(errorMessage);
      }
    );
  }

  close() {
    this.dialogRef.close(undefined);
  }
}
