import { Injectable } from "@angular/core";
import { ConfirmationDialogComponent } from "src/app/core/components/dialog/confirmation-dialog/confirmation-dialog.component";
import { SuccessDialogComponent } from "../components/dialog/success-dialog/success-dialog.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ErrorDialogComponent } from "../components/dialog/error-dialog/error-dialog.component";
import { WarningDialogComponent } from "../components/dialog/warning-dialog/warning-dialog.component";

@Injectable({
  providedIn: "root",
})
export class MessageBoxService {
  confirmDialogRef: MatDialogRef<ConfirmationDialogComponent, void>;
  successDialogRef: MatDialogRef<SuccessDialogComponent, void>;
  errorDialogRef: MatDialogRef<ErrorDialogComponent, void>;

  constructor(public dialog: MatDialog) {}

  public showConfirmationMessage(
    message: string,
    onConfirm: (boolean) => void,
    icon?: string,
    title?: string,
    primaryActionLabel?: string,
    secondaryActionLabel?: string,
    accentColor?: string
  ) {
    this.confirmDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        msg: message,
        icon,
        title,
        primaryActionLabel,
        secondaryActionLabel,
        accentColor,
      },
    });
    this.confirmDialogRef.componentInstance.confirm.subscribe((confirm) => {
      if (confirm) {
        onConfirm(confirm);
      }
      this.confirmDialogRef.close();
    });
  }

  public showDeleteConfirmationMessage(message: string, onConfirm: (boolean) => void, title?: string) {
    this.showConfirmationMessage(message, onConfirm, "delete", title ?? "Delete", "Delete", "Cancel", "error");
  }

  public showError(errorCategory: string, errorMessage: string, errorDetails?: string) {
    this.errorDialogRef = this.dialog.open(ErrorDialogComponent, {
      data: {
        errorCategory,
        errorMessage,
        errorDetails,
      },
    });
  }

  public showWarning(warningMessage: string) {
    this.dialog.open(WarningDialogComponent, {
      data: {
        warningMessage: warningMessage,
      },
    });
  }

  public showErrorMessage(errorMessage: string, errorDetails?: string) {
    this.errorDialogRef = this.dialog.open(ErrorDialogComponent, {
      data: {
        errorCategory: "Error",
        errorMessage,
        errorDetails,
      },
    });
  }

  public showSuccessMessage(message: string, secondaryMessage?: string) {
    this.successDialogRef = this.dialog.open(SuccessDialogComponent, {
      data: {
        message,
        secondaryMessage,
      },
    });
  }

  public handleServerError(errorResponse: any, requestType: string): void {
    if (errorResponse) {
      console.log(errorResponse);
      if (errorResponse.error && errorResponse.error.subErrors) {
        const errors = this.getMessageFromSubErrors(errorResponse.error.subErrors);
        const msg = requestType + " request failed.";
        this.showErrors(errors, msg);
      } else {
        this.showError(
          "Server Error",
          "The application has encountered an unexpected error. Please retry in some time or log in a Feedback ticket for us to look into it."
        );
      }
    }
  }

  showErrors(errors: any[], msgLabel: string) {
    const errorDetails = errors.map((err) => err.message).join("\n");
    const data = {
      errorDetails: errorDetails,
      errorCategory: "Error",
      errorMessage: msgLabel,
      showFieldValueOnly: false,
    };
    this.dialog.open(ErrorDialogComponent, {
      panelClass: "error-dialog-container",
      data: data,
    });
  }

  getMessageFromSubErrors(subErrors: any) {
    const errMsgs = new Set(
      subErrors.filter((subError) => subError.message != null).map((subError) => subError.message)
    );
    const errors: any = [];
    errMsgs.forEach((err) => errors.push({ name: "Error", message: err }));
    return errors;
  }
}
