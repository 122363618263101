<div class="custom-poi-container" mat-dialog-content>
  <div class="auto-select-modal">
    <h4 mat-dialog-title>
      <img src="../../../../../assets/images/upload-blue-icon.svg" alt="" class="img-20" />
      <span class="title-text">POI SET DEFINITION</span>
    </h4>
    <div role="button" class="close-container modal-close" (click)="close()">
      <img src="../../../../../assets/images/close-round-blue.svg" alt="" class="img-20" />
    </div>
  </div>

  <div class="upload-file-input-container">
    <input
      type="file"
      accept=".csv"
      #fileInput
      (change)="onFileSelection(fileInput.files)"
      multiple
      class="file-input"
      placeholder="Upload File"
    />

    <div class="verify-button-container">
      <button class="secondary-btn" [disabled]="!control.isVerifyButtonEnabled" (click)="onVerify()">Verify</button>
    </div>
  </div>

  <div class="download-template-container">
    <a class="download-template" (click)="downloadPoiSetTemplate()">POI Set Template</a>
  </div>

  <div class="validation-result-box" *ngIf="control.isValidationResultVisible">
    <div class="results-text">Results</div>
    <div class="validation-result">
      <div class="validation-result-numbers">
        <div class="validation-count">
          <img class="validation-icon img-20" src="../../../../../../../assets/images/valid.svg" />
          <span class="validation-count-text"
            ><span class="validation-count-number">{{ validRows }}</span> Valid</span
          >
        </div>
        <div class="validation-count">
          <img class="validation-icon img-20" src="../../../../../../../assets/images/invalid.svg" />
          <span class="validation-count-text"
            ><span class="validation-count-number">{{ invalidRows }}</span> Rejected</span
          >
        </div>
      </div>
      <div class="validation-download" *ngIf="control.isValidationFileDownloadEnable">
        <img class="validation-icon img-20" src="../../../../../../../assets/images/excel.svg" />
        <a class="download-text" (click)="onValidationResultDownload()">validation-result.csv</a>
      </div>
    </div>
  </div>

  <div class="input-form-container">
    <div class="form-group">
      <input
        type="text"
        [(ngModel)]="customPOISetName"
        (input)="onTyping($event)"
        class="form-control"
        placeholder=" "
      />
      <label>POI Set Name</label>
      <span *ngIf="control.isNameInputErrorVisible" class="error-msg">{{ setNameErrorMessage }}</span>
    </div>
  </div>

  <div class="button-container">
    <button class="primary-btn" [disabled]="!control.isSubmitButtonEnabled" (click)="onProceed()">PROCEED</button>
  </div>
</div>
